import React from 'react';
import { ShowAlert } from '../../Component/ShowAlert';

interface UserVerificationFailureProps {}

interface UserVerificationFailureState {}

class UserVerificationFailure extends React.Component<UserVerificationFailureProps, UserVerificationFailureState> {
    
    constructor(props_: UserVerificationFailureProps) {
            super(props_);
    }

    async componentDidMount() {
        ShowAlert("PassportPay", `The email ID has been invalid.`, 'error', false, false, false);
    }
    
    public render() {
        return (
            <div className='body'></div>
        )
    }
}

export default UserVerificationFailure;