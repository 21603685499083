import BaseService from "./BaseService";

export default class FeeService extends BaseService {
    public static getWeeklyLimit(token: string) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/limit', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
    }

    public static updateWeeklyLimit(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'payment/limit', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
}