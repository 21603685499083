import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AddCard from './Pages/AddCard/AddCard';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';
import PlaidRedirect from './Pages/PlaidRediect/PlaidRedirect';
import CardSuccess from './Pages/CardSuccess/CardSuccess';
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import TransactionLogs from './Pages/TransactionLogs/TransactionLogs';
import UserLogs from './Pages/UserLogs/UserLogs';
import Compliance from './Pages/Compliance/Compliance';
import UserDetails from './Pages/UserDetails/UserDetails';
import UserVerification from './Pages/UserVerification/UserVerification';
import UserVerificationSuccess from './Pages/UserVerification/UserVerificationSuccess';
import UserVerificationFailure from './Pages/UserVerification/UserVerificationFailure';

export default (
    <Routes>
        <Route path="/deleteaccount" element={<DeleteAccount />} />
        <Route path="/addcard" element={<AddCard />} />
        <Route path="/plaid" element={<PlaidRedirect />} />
        <Route path="/cardsuccess" element={<CardSuccess />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transactionlogs/:id?" element={<TransactionLogs />} />
        <Route path="/useractions" element={<UserLogs />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/userDetails" element={<UserDetails/>} />
        <Route path="/userVerification/:id?" element={<UserVerification/>} />
        <Route path="/userVerificationSuccess" element={<UserVerificationSuccess/>} />
        <Route path="/userVerificationFailure" element={<UserVerificationFailure/>} />
        <Route path="/" element={<Login />} />
    </Routes>
);