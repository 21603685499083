import React, { useEffect } from 'react';
import './App.css';
import routes from './Router';
import NavBar from './Pages/NavBar/NavBarLeft';
import NavBarTop from './Pages/NavBar/NavBarTop';
import { SyncProvider } from './Contexts/BatchSyncContext';
import { ThemeProvider } from '@mui/material/styles';
import { greenTheme } from './Themes/GreenTheme';
import LoaderComponent from './Component/LoaderComponent';

function App() {
  let isMenuVisible: boolean = leftNavVisibleType();

  useEffect(() => {
    const root = document.querySelector('.custom-theme') as HTMLElement;
    if (root) {
      root.style.setProperty('--primary-main', greenTheme.palette.primary.main);
      root.style.setProperty('--primary-contrast', greenTheme.palette.primary.contrastText);
      root.style.setProperty('--secondary-main', greenTheme.palette.secondary.main);
      root.style.setProperty('--secondary-contrast', greenTheme.palette.secondary.contrastText);
    }
  }, []);

  return (
    <div className="App">
      <SyncProvider>
        <ThemeProvider theme={greenTheme}>

          {
            isMenuVisible ? <NavBar /> : null
          }

          <div className="body-container">
            {
              // Header will not display in registration screen
              isMenuVisible ? <NavBarTop /> : null
            }
            {routes}
          </div>
        </ThemeProvider>
        <LoaderComponent />
      </SyncProvider>
    </div>
  );

  function leftNavVisibleType() {
    let url_ = window.location.href.replace(window.location.origin, '').replace('#', '');
    let visibleType = false;

    if (url_.indexOf('/dashboard') !== -1 || url_.indexOf('/transactionlogs') !== -1 || url_.indexOf('/useractions') !== -1 || url_.indexOf('/compliance') !== -1 || url_.indexOf('/userdetails') !== -1) {
      visibleType = true;
    }

    return visibleType;
  }
}

export default App;