import BaseService from "./BaseService";

export default class UserVerificationService extends BaseService {
    public static verifyUser(postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + `signup/verifyEmail/${postData}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
    }
}