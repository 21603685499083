import React from "react";
import { ITransactionDetails, TransactionDetailsProps, TransactionDetailsState } from "../Interface";
import './TransactionDetails.css';
import DataGrid from "../../../Component/DataGrid/DataGrid";
import { TableColumn } from "react-data-table-component";
import { amountFormat } from "../../../Component/helper";
import CustomMaterialMenu from "../../../Component/DataGrid/Plugins/CustomMaterialMenu/CustomMaterialMenu";
import RefundDialogPopop from "../RefundDialog/RefundDialogPopop";
import TransactionService from "../../../Services/TransactionService";
import { ShowAlert } from "../../../Component/ShowAlert";

class TransactionDetails extends React.Component<TransactionDetailsProps, TransactionDetailsState> {
    private tableColumns: TableColumn<ITransactionDetails>[] = [
        {
            name: 'Account Name',
            selector: row => row.account_name,
            sortable: false,
            id: "AccountName",

        },
        {
            name: 'Account Number',
            selector: row => row.account,
            sortable: false,
            id: "AccountNumber",

        },
        {
            name: 'Biller Name',
            selector: row => row.biller_name,
            sortable: false,
            id: "BillerName"
        },
        {
            name: 'Channel',
            selector: row => row.channel,
            sortable: false,
            id: "Channel"
        },
        {
            name: 'Sender Currency',
            selector: row => row.sender_currency,
            sortable: false,
            id: "sendercurrency"
        },
        {
            name: 'Sender Amount',
            selector: row => amountFormat(row.sender_amount, 'en-US', 'USD'),
            sortable: false,
            id: "senderAmount"
        },
        {
            name: 'Paid Amount',
            selector: row => amountFormat(row.paid_amount, 'en-US', 'USD'),
            sortable: false,
            id: "senderAmount"
        },
        {
            name: 'Destination Currency',
            selector: row => row.destination_currency,
            sortable: false,
            id: "destinationcurrency"
        },
        {
            name: 'Destination Amount',
            selector: row => amountFormat(row.destination_amount, 'en-GH', 'GHS'),
            sortable: false,
            id: "destinationAmount"
        },
        {
            name: 'Status',
            selector: row => row.transaction_status,
            sortable: false,
            id: "status"
        },
        {
            cell: row => <CustomMaterialMenu
                fontSize="small"
                multiPopoverItems={row.transaction_status == "failed" ? [
                    {
                        key: "refundToWallet",
                        label: "Refund to wallet"
                    },
                    {
                        key: "refundToCard",
                        label: "Refund to card"
                    }
                ] : [
                    {
                        key: "refundToWallet",
                        label: "Refund to wallet"
                    }
                ]}
                onPopoverClick={(key_: string) => {
                    // alert(key_);
                    this.setState({
                        popupState: true,
                        selectedRow: row,
                        refundMode: key_
                    })
                }}
                disabled={row.transaction_status == "refunded" || row.transaction_status == "processing"}
            />,
            allowOverflow: true,
            button: true,
            width: '56px'
        }
    ]

    constructor(props_: TransactionDetailsProps) {
        super(props_);

        this.state = {
            popupState: false,
            selectedRow: null,
            refundMode: ''
        }
    }

    public render() {
        return (
            <div className="details-container">
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <h1 style={{ flexGrow: 1 }}>Transaction Details:</h1>
                    <span>Sender's Email Id:&nbsp;</span>
                    <b>{this.props.details.sender_email}</b>
                    <span style={{width: "50px"}}></span>
                    <span>Sender's Mobile:&nbsp;</span>
                    <b>{this.props.details.sender_mobile}</b>
                </div>
                <DataGrid
                    columns={this.tableColumns}
                    data={this.props.details.transaction_details}
                    expandableRows={false}
                    pagination={false}
                />
                <RefundDialogPopop
                    open={this.state.popupState}
                    onClose={(state: boolean, selectedAmount: number, action: string) => {
                        if (action == "ok") {
                            this.initiateRefund(state, selectedAmount);
                        }
                        else {
                            this.setState({
                                popupState: state,
                                selectedRow: null
                            });
                        }
                    }}
                    paidAmount={this.state.selectedRow ? (this.state.selectedRow.transaction_status == "failed" ? this.state.selectedRow.paid_amount : this.state.selectedRow.sender_amount) : 0}
                />
            </div>
        )
    }

    private async initiateRefund(state: boolean, amount: number) {
        let postdata: any = {
            "transtactionId": this.state.selectedRow ? this.state.selectedRow.transaction_id : "",
            "amount": amount.toString(),
            "refundType": this.state.refundMode.indexOf("wallet") ? "wallet" : "source"
        }

        let tr = await TransactionService.initiatetransaction(this.props.accessToken, postdata);

        if (tr.ok) {
            this.setState({
                popupState: state,
                selectedRow: null
            }, () => {
                ShowAlert("PassportPay", "The refund request has been successfully processed.", 'success').then(() => {
                    if (this.props.onRefundDone) {
                        this.props.onRefundDone(true);
                    }
                })
            });
        }
        else {
            this.setState({
                popupState: state,
                selectedRow: null
            }, () => {
                tr.json().then(resp => {
                    ShowAlert("PassportPay", `${JSON.parse(resp)}`, 'error')
                });
            });
            
        }

        /*TransactionService.initiatetransaction(this.props.accessToken, postdata).then(tr => {
            if (tr.ok) {
                this.setState({
                    popupState: state,
                    selectedRow: null
                }, () => {
                    ShowAlert("PassportPay", "The refund request has been successfully processed.", 'success')
                });
            }
            else {
                tr.json().then(resp => {
                    this.setState({
                        popupState: state,
                        selectedRow: null
                    }, () => {
                        ShowAlert("PassportPay", `${resp}`, 'error')
                    });
                });
            }
        });*/
    }
}

export default TransactionDetails;