import BaseService from "./BaseService";

export default class UserDetailsService extends BaseService {
    public static getUserDetails(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'user/', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }

    public static UpdateUserStatus(token: string, postData: any) {
        return BaseService.sendRequest(process.env.REACT_APP_API_URL + 'user/update-status', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
    }
}