import React from 'react';
import { ShowAlert } from '../../Component/ShowAlert';

interface UserVerificationSuccessProps {}

interface UserVerificationSuccessState {
    emailVerifiedStatus: string;
}

class UserVerificationSuccess extends React.Component<UserVerificationSuccessProps, UserVerificationSuccessState> {
    
    constructor(props_: UserVerificationSuccessProps) {
            super(props_);

            let URLParams = new URLSearchParams(window.location.search);
            let status = URLParams.get("status");

            this.state = {
                emailVerifiedStatus: status ? status : '' , 
            }
    }

    async componentDidMount() {
        if (this.state.emailVerifiedStatus == '0') {
            ShowAlert("PassportPay", `The email ID has been successfully verified.`, 'success', false, false, false);
        } else {
            ShowAlert("PassportPay", `The email ID has already been verified.`, 'success', false, false, false);
        }        
    }
    
    public render() {
        return (
            <div className='body'></div>
        )
    }
}

export default UserVerificationSuccess;