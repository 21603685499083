import React from 'react';
import { loadFromLocalStorage } from '../../Common/Validation/LocalStorage';
import DataGrid from '../../Component/DataGrid/DataGrid';
import './UserDetails.css';
import { TableColumn } from 'react-data-table-component';
import { IUserDetails, UserDetailsProps, UserDetailsState } from './Interface';
import CustomMaterialMenu from '../../Component/DataGrid/Plugins/CustomMaterialMenu/CustomMaterialMenu';
import { ShowAlert } from '../../Component/ShowAlert';
import UserDetailsService from '../../Services/UserDetailsService';

class UserDetails extends React.Component<UserDetailsProps, UserDetailsState> {
    
    private tableColumns: TableColumn<IUserDetails>[] = [
        {
            name: 'User ID',
            selector: row => row.user_id,
            sortable: false,
            omit: true,
            id: "UserId",
        },
        {
            name: 'User Name',
            selector: row => row.name,
            sortable: false,
            id: "UserName",

        },
        {
            name: 'User Email',
            selector: row => row.email,
            sortable: false,
            id: "UserEmail",

        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile_number,
            sortable: false,
            id: "MobileNumber"
        },
        {
            name: 'Status',
            selector: row => row.user_status_id,
            sortable: false,
            id: "Status",
            cell: (row) => {
                const statusLabel = row.user_status_id === 2 ? <span className='status-label active'>ACTIVE</span> : 
                    row.user_status_id === 3 ? <span className="status-label blocked">BLOCKED</span> :
                    <span className="status-label unknown">UNKNOWN</span>;
                return statusLabel;
            }
        },
        {
            name: 'Address Line 1',
            selector: row => row.address_line1,
            sortable: false,
            id: "AddressLine1"
        },
        {
            name: 'Address Line 2',
            selector: row => row.address_line2,
            sortable: false,
            id: "AddressLine2"
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: false,
            id: "City"
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: false,
            id: "State"
        },
        {
            name: 'Zip',
            selector: row => row.zip,
            sortable: false,
            id: "ZIP"
        },
        {
            cell: row => <CustomMaterialMenu
                fontSize="small"
                multiPopoverItems={row.user_status_id === 2 ? [
                    {
                        key: "blocked",
                        label: "Block User"
                    }
                ]: row.user_status_id === 3 ? [{
                    key: "activated",
                    label: "Activate User"
                }] : []}
                onPopoverClick={(key_: string, label_: string) => {
                    if(key_ === "blocked") {
                        this.UpdateUserStatus(row, key_, 3);
                    } else if(key_ === "activated") {
                        this.UpdateUserStatus(row, key_, 2);
                    }
                }}
                disabled={false}
            />,
            allowOverflow: true,
            button: true,
            width: '120px'
        }
    ]

    constructor(props_: UserDetailsProps) {
        super(props_);

        this.state = {
            accessToken: "",
            userData: [],
            currentRowsPerPage: 10,
            currentPage: 1,
            totalRows: 100,
            searchText: ""
        }
    }

    async componentDidMount() {
        const accesstoken_ = await loadFromLocalStorage('accessToken');

        if (accesstoken_) {
            this.setState({
                accessToken: accesstoken_.toString()
            }, () => {
                this.getUserDetails();
            })

        }
        else {
            localStorage.clear();
            window.location.href = '/'
        }
    }

    public render() {
        return (
            <div className="body-sub-container table-container">
                <DataGrid
                    title='User Details'
                    search='User Details'
                    defaultSearch={this.state.searchText}
                    onSearch={(searchText_: string) => {
                        this.setState({
                            searchText: searchText_,
                            currentPage: 1
                        }, () => {
                            this.getUserDetails();
                        })
                    }}
                    columns={this.tableColumns}
                    data={this.state.userData}
                    onChangePage={(page: number, totalRows: number) => {
                        this.setState({
                            currentPage: page
                        }, ()=> {
                            this.getUserDetails();
                        })
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage_: number, currentPage_: number) => {
                        this.setState({
                            currentRowsPerPage: currentRowsPerPage_,
                            currentPage: currentPage_
                        }, () => {
                            this.getUserDetails();
                        })
                    }}
                    pagination
                    paginationTotalRows={this.state.totalRows}
                    exportCsv
                />
            </div>
        )
    }

    private getUserDetails() {
        let pageIndex: number = (this.state.currentPage - 1);
        let pagination: any = {
            "pageNumber": pageIndex,
            "recordPerPage": this.state.currentRowsPerPage
        }
        
        if (this.state.searchText) {
            pagination["searchText"] = this.state.searchText
        }

        UserDetailsService.getUserDetails(this.state.accessToken, pagination).then(tr => {
            if(tr.ok) {
                tr.json().then(resp => {
                    this.setState({
                        userData: resp.userDetailsList,
                        totalRows: resp.pageCount
                    }, () => {
                        console.log(this.state.userData);                        
                    })
                });
            }
        });
    }

    private async UpdateUserStatus(row_: IUserDetails, key_: string, status: number) {
        let postdata: any = {
            "userId" : row_.user_id,
            "userStatus" : status
        }

        let resp = await UserDetailsService.UpdateUserStatus(this.state.accessToken, postdata);

        if(resp.ok) {
            ShowAlert("PassportPay", `The user has been marked as ${key_}.`, 'success').then(() => {
                this.getUserDetails();
            });
        }
    }    
}

export default UserDetails;