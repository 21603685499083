import React from 'react';
import UserVerificationService from '../../Services/UserVerificationService';
import { ShowAlert } from '../../Component/ShowAlert';

interface UserVerificationProps { }

interface UserVerificationState {
    userVerificationId: string;
}
class UserVerification extends React.Component<UserVerificationProps, UserVerificationState> {

    constructor(props_: UserVerificationProps) {
        super(props_);

        let URLParams = new URLSearchParams(window.location.search);
        let verificationId = URLParams.get("id");

        this.state = {
            userVerificationId: verificationId ? verificationId : '',
        }
    }

    async componentDidMount() {
        UserVerificationService.verifyUser(this.state.userVerificationId).then(r => {
            if (r.status == 200) {
                r.json().then(resp => {
                    if (resp.is_email_verified == 0) {
                        ShowAlert("PassportPay", `The email ID has been successfully verified.`, 'success', false, false, false);
                    } else {
                        ShowAlert("PassportPay", `The email ID has already been verified.`, 'success', false, false, false);
                    }
                    // window.location.href = `/userVerificationSuccess?status=${resp.is_email_verified}`;
                });
            }
            else {
                ShowAlert("PassportPay", `Invalid URL. Please resend the email.`, 'error', false, false, false);
                // window.location.href = '/userVerificationFailure';
            }
            // r.json().then(resp => {                 
            //     if (resp.status == 200) {
            //         window.location.href = `/userVerificationSuccess?status=${resp.is_email_verified}`;
            //     }
            //     else {
            //         window.location.href = '/userVerificationFailure';
            //     }
            // });
        });
    }

    public render() {
        return (
            <div className='body'></div>
        )
    }
}

export default UserVerification;