import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';
import { amountFormat } from '../../../Component/helper';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import './GeneralSettingDialogPopop.css';
import InputAdornment from '@mui/material/InputAdornment';
import LimitService from '../../../Services/LimitService';
import AlertDialogPopop from '../../../Component/AlertDialogPopop/AlertDialogPopop';


interface GeneralSettingDialogPopopProps {
    open: boolean;
    onClose?: (state: boolean, action: string) => void;
    accessToken: string;
}
interface GeneralSettingDialogPopopState {
    weeklyLimit: string,
    inputType: string,
    limitConfirm: boolean
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class GeneralSettingDialogPopop extends React.Component<GeneralSettingDialogPopopProps, GeneralSettingDialogPopopState> {
    constructor(props_: GeneralSettingDialogPopopProps) {
        super(props_);

        this.state = {
            weeklyLimit: '0.00',
            inputType: 'text',
            limitConfirm: false
        }
    }

    componentDidMount(): void {
        this.getGeneralSetting();
    }

    public render() {
        return (
            <>
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                        this.handleClose('cancel')
                    }}
                    fullWidth={true}
                    aria-describedby="alert-dialog-slide-description"
                    className='setting-popup'
                >
                    <DialogTitle>{"General Settings"}</DialogTitle>
                    <DialogContent>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                <FormLabel className='weekly-limit-label'>Weekly Limit</FormLabel>
                                <TextField
                                    variant="outlined"
                                    color="primary"
                                    value={this.state.weeklyLimit}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onChange={(text) => {
                                        this.setState({ weeklyLimit: text.target.value });
                                    }}
                                    onBlur={() => {
                                        let n_ = this.state.weeklyLimit ? this.state.weeklyLimit : '0';
                                        let updatedAmount = amountFormat(parseFloat(n_.replace(/,/g, '')), 'en-US', 'USD').replace("$", "");
                                        this.setState({ weeklyLimit: updatedAmount, inputType: 'text' });
                                    }}
                                    onFocus={() => {
                                        let n_ = this.state.weeklyLimit.replace(/,/g, '');
                                        this.setState({ weeklyLimit: n_, inputType: 'number' });
                                    }}
                                    type={this.state.inputType}
                                />
                            </FormGroup>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.handleClose('cancel');
                        }}>Cancel</Button>
                        <Button onClick={() => {
                            this.setState({
                                limitConfirm: true
                            })
                        }} color='secondary' variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
                <AlertDialogPopop
                    open={this.state.limitConfirm}
                    alertTitle='Confirm Limit'
                    alertBody={`Are you sure want to save weekly limit “${this.state.weeklyLimit}”?`}
                    onClose={(state_, action_) => {
                        this.setState({
                            limitConfirm: false
                        }, () => {
                            if (action_ == "ok") {
                                this.handleClose('ok');
                            }
                        })
                    }}
                />
            </>
        )
    }

    private handleClose = (action: string) => {
        if (action == "ok") {
            let postData = {
                "weeklyLimit": this.state.weeklyLimit
            }

            LimitService.updateWeeklyLimit(this.props.accessToken, postData).then(tr => {
                if (tr.ok) {
                    if (this.props.onClose) {
                        this.props.onClose(false, action);
                    }
                }
                else {
                    localStorage.clear();
                    window.location.href = '/'
                }
            });
        }
        else if (this.props.onClose) {
            this.props.onClose(false, action);
        }
    };

    private getGeneralSetting() {
        LimitService.getWeeklyLimit(this.props.accessToken).then(tr => {
            if (tr.ok) {
                tr.json().then(resp => {
                    let n_ = resp.weeklyLimit ? resp.weeklyLimit : '0';
                    let updatedAmount = amountFormat(parseFloat(n_.replace(/,/g, '')), 'en-US', 'USD').replace("$", "");
                    this.setState({
                        // weeklyLimit: Number(resp.weeklyLimit).toFixed(2)
                        weeklyLimit: updatedAmount,
                        inputType: 'text'
                    })
                });
            }
        });
    }
}

export default GeneralSettingDialogPopop;