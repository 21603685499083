import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function ShowAlert(title: string, text: string, icon: any, allowOutsideClick: boolean = false, allowEscapeKey: boolean = false, showConfirmButton: boolean = true) {
    return withReactContent(Swal).fire({title, text, icon, allowOutsideClick: allowOutsideClick, allowEscapeKey: allowEscapeKey, showConfirmButton: showConfirmButton}).then(result => {
        return result.value;
    });
}

export function alertClose() {
    return Swal.close();
}
